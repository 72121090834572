.stroke {
  stroke-dasharray: 2 6;
  animation: dash 10s linear infinite;
}

@keyframes dash {
  to {
    stroke-dashoffset: -80;
  }
}

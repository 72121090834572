.mask {
  overflow: hidden;
  display: inline-block;
}

.reveal {
  transform: translateX(100%);
  animation: reveal ease-in-out 0.2s forwards;
  padding-right: 1.5rem;
  line-height: 1;
}

.mask + .mask .reveal {
  transform: translateX(100%);
  animation: reveal ease-in-out 0.2s 0.1s forwards;
}

.mask + .mask + .mask .reveal {
  transform: translateX(100%);
  animation: reveal ease-in-out 0.2s 0.2s forwards;
}

.mask + .mask + .mask + .mask .reveal {
  transform: translateX(100%);
  animation: reveal ease-in-out 0.2s 0.3s forwards;
}

.mask + .mask + .mask + .mask + .mask .reveal {
  transform: translateX(100%);
  animation: reveal ease-in-out 0.2s 0.4s forwards;
}

.mask + .mask + .mask + .mask + .mask + .mask .reveal {
  transform: translateX(100%);
  animation: reveal ease-in-out 0.2s 0.5s forwards;
}

@keyframes reveal {
  from {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@media (max-width: 640px) {
  .reveal {
    padding-right: 1rem;
  }
}

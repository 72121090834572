.cardAnimation {
  position: absolute;
  transform: rotate(-75deg);
  top: -80px;
  right: 40px;
}

/* Card */
.card {
  margin-left: 20px;
  animation: card-intro ease-in-out 1s;
}

@keyframes card-intro {
  0% {
    opacity: 0;
    transform: translateY(400px);
  }

  60% {
    opacity: 1;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Big Blob */
.bigBlobGroup {
  position: absolute;
  top: 0;
  left: 0;
  width: 410px;
  height: 516px;
  filter: url("#bigGoo");
  z-index: -1;
}

.bigBlobPosition {
  position: absolute;
  top: 100px;
  left: 75px;
}

.bigCircle {
  width: 60px;
  height: 60px;
  background: black;
  position: absolute;
  border-radius: 50%;
}

.bigCircle {
  animation: big-circle-anim-up ease-in-out 8s infinite alternate;
}

.bigCircle + .bigCircle {
  animation: big-circle-anim-down ease-in-out 8s infinite alternate;
}

.bigCircle + .bigCircle + .bigCircle {
  animation: big-circle-anim-down2 ease-in-out 8s infinite alternate;
}

@keyframes big-circle-anim-up {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(-45px);
  }
}

@keyframes big-circle-anim-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(50px);
  }
}

@keyframes big-circle-anim-down2 {
  0% {
    transform: translateY(35px);
  }
  100% {
    transform: translateY(145px);
  }
}

/* Small Blob */
.smallBlobGroup {
  position: absolute;
  top: 0;
  left: 0;
  width: 410px;
  height: 516px;
  filter: url("#smallGoo");
}

.smallBlobPosition {
  position: absolute;
  top: 340px;
  left: 4px;
}

.smallCircle {
  width: 12px;
  height: 12px;
  background: #f0314b;
  position: absolute;
  border-radius: 50%;
}

.smallCircle {
  animation: small-circle-anim-up ease-in-out 5s infinite alternate;
}

.smallCircle + .smallCircle {
  animation: small-circle-anim-down ease-in-out 5s infinite alternate;
}

@keyframes small-circle-anim-up {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes small-circle-anim-down {
  0% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(15px);
  }
}

/* Circles */

.redCircleMd,
.redCircleSm,
.blueCircleSm,
.blueCircleMd,
.greenCircleSm,
.greenCircleMd {
  position: absolute;
  border-radius: 50%;
}

.redCircleMd {
  width: 17px;
  height: 17px;
  background: #f0314b;
  top: 450px;
  left: 1px;
  animation: circle-enter ease-in-out 3s infinite alternate;
}

.redCircleSm {
  width: 8px;
  height: 8px;
  background: #f0314b;
  top: 115px;
  left: 370px;
  animation: circle-enter ease-in-out 2s infinite alternate;
}

.blueCircleSm {
  width: 10px;
  height: 10px;
  background: #00fff0;
  top: 225px;
  left: 25px;
  animation: circle-enter ease-in-out 2.5s infinite alternate;
}

.blueCircleMd {
  width: 24px;
  height: 24px;
  background: #00fff0;
  top: 0px;
  left: 290px;
  animation: circle-enter ease-in-out 3.5s infinite alternate;
}

.greenCircleSm {
  width: 10px;
  height: 10px;
  background: #55f5a3;
  top: 480px;
  left: 396px;
  animation: circle-enter ease-in-out 3s infinite alternate;
}

.greenCircleMd {
  width: 18px;
  height: 18px;
  background: #55f5a3;
  top: 430px;
  left: 392px;
  animation: circle-enter ease-in-out 2s infinite alternate;
}

@keyframes circle-enter {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Strokes */

.stroke {
  stroke-dasharray: 4;
  animation: dash 10s linear infinite;
}

@keyframes dash {
  to {
    stroke-dashoffset: 80;
  }
}

@media (max-width: 640px) {
  .cardAnimation {
    display: none;
  }
}

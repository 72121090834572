.completeGraphic {
  width: 396px;
  height: 410px;
  position: relative;
  margin: 0 auto;
}

@media (max-width: 640px) {
  .completeGraphic {
    display: none;
  }
}

/* Small Blobs */
.smallBlobGroup {
  position: absolute;
  top: 0;
  left: 0;
  width: 396px;
  height: 410px;
  filter: url("#smallGoo");
}

.smallBlobPosition1 {
  position: absolute;
  top: 218px;
  left: 90px;
}

.smallBlobPosition2 {
  position: absolute;
  top: 125px;
  left: 135px;
  transform: rotate(45deg);
}

.smallBlobPosition3 {
  position: absolute;
  top: 311px;
  left: 120px;
  transform: rotate(-45deg);
}

.smallBlobPosition4 {
  position: absolute;
  top: 358px;
  left: 62px;
  transform: rotate(-45deg);
}

.smallBlobPosition5 {
  position: absolute;
  top: 225px;
  left: 4px;
}

.smallBlobPosition6 {
  position: absolute;
  top: 60px;
  left: 50px;
  transform: rotate(45deg);
}

.smallCircle1,
.smallCircle2,
.smallCircle3 {
  width: 12px;
  height: 12px;
  background: black;
  position: absolute;
  border-radius: 50%;
}

.smallCircle4 {
  width: 8px;
  height: 8px;
  background: black;
  position: absolute;
  top: 8px;
  left: 45px;
  border-radius: 50%;
  transform: rotate(-45deg);
  animation: small-circle-anim-left ease-in-out 5s infinite alternate;
}

.smallCircle5 {
  width: 10px;
  height: 10px;
  background: black;
  position: absolute;
  bottom: -15px;
  left: -20px;
  border-radius: 50%;
  animation: small-circle-anim-right ease-in-out 4s infinite alternate;
}

.smallCircle6 {
  width: 8px;
  height: 8px;
  background: black;
  position: absolute;
  bottom: -3px;
  left: 0px;
  border-radius: 50%;
  animation: small-circle-anim-right ease-in-out 4s infinite alternate;
}

.smallCircle7 {
  width: 12px;
  height: 12px;
  background: black;
  position: absolute;
  bottom: -5px;
  left: 50px;
  border-radius: 50%;
  animation: small-circle-anim-left ease-in-out 5s infinite alternate;
}

.smallCircle8 {
  width: 10px;
  height: 10px;
  background: black;
  position: absolute;
  top: -14px;
  left: 15px;
  border-radius: 50%;
  animation: small-circle-anim-right ease-in-out 3s infinite alternate;
}

.smallCircle9 {
  width: 8px;
  height: 8px;
  background: black;
  position: absolute;
  top: -12px;
  left: 70px;
  border-radius: 50%;
  animation: small-circle-anim-left ease-in-out 5s infinite alternate;
}

.smallCircle1 {
  animation: small-circle-anim-left ease-in-out 5s infinite alternate;
}

.smallCircle1 + .smallCircle1 {
  animation: small-circle-anim-right ease-in-out 5s infinite alternate;
}

.smallCircle2 {
  animation: small-circle-anim-left ease-in-out 4s infinite alternate;
}

.smallCircle2 + .smallCircle2 {
  animation: small-circle-anim-right ease-in-out 4s infinite alternate;
}

.smallCircle3 {
  animation: small-circle-anim-left ease-in-out 3s infinite alternate;
}

.smallCircle3 + .smallCircle3 {
  animation: small-circle-anim-right ease-in-out 3s infinite alternate;
}

@keyframes small-circle-anim-left {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-10px);
  }
}

@keyframes small-circle-anim-right {
  0% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(15px);
  }
}

/* Circles */

.redCircleMd,
.redCircleSm,
.redCircleLg,
.blueCircleSm,
.blueCircleMd,
.greenCircleSm,
.greenCircleMd,
.greenCircleLg,
.whiteCircleSm,
.whiteCircleMd,
.whiteCircleLg,
.outlineCircleSm,
.outlineCircleMd {
  position: absolute;
  border-radius: 50%;
}

.blueCircleSm {
  width: 12px;
  height: 12px;
  background: #00fff0;
  top: 119px;
  left: 305px;
  animation: circle-enter ease-in-out 2.5s infinite alternate;
}

.blueCircleMd {
  width: 28px;
  height: 28px;
  background: #00fff0;
  top: 62px;
  left: 345px;
  animation: circle-enter ease-in-out 4.5s infinite alternate;
}

.redCircleSm {
  width: 8px;
  height: 8px;
  background: #f0314b;
  top: 315px;
  left: 323px;
  animation: circle-enter ease-in-out 3s infinite alternate;
}

.redCircleMd {
  width: 12px;
  height: 12px;
  background: #f0314b;
  top: 323px;
  left: 332px;
  animation: circle-enter ease-in-out 3.5s infinite alternate;
}

.redCircleLg {
  width: 16px;
  height: 16px;
  background: #f0314b;
  top: 334px;
  left: 345px;
  animation: circle-enter ease-in-out 4s infinite alternate;
}

.greenCircleSm {
  width: 9px;
  height: 9px;
  background: #55f5a3;
  top: 210px;
  left: 395px;
  animation: circle-enter ease-in-out 3s infinite alternate;
}

.greenCircleMd {
  width: 11px;
  height: 11px;
  background: #55f5a3;
  top: 208px;
  left: 345px;
  animation: circle-enter ease-in-out 2s infinite alternate;
}

.greenCircleLg {
  width: 19px;
  height: 19px;
  background: #55f5a3;
  top: 204px;
  left: 365px;
  animation: circle-enter ease-in-out 2.5s infinite alternate;
}

.whiteCircleSm {
  width: 12px;
  height: 12px;
  background: white;
  border: 2px solid black;
  top: 35px;
  left: 209px;
  animation: circle-enter ease-in-out 2.5s infinite alternate;
}

.whiteCircleMd {
  width: 12px;
  height: 12px;
  background: white;
  border: 2px solid black;
  top: 8px;
  left: 209px;
  animation: circle-enter ease-in-out 3.5s infinite alternate;
}

.whiteCircleLg {
  width: 12px;
  height: 12px;
  background: white;
  border: 2px solid black;
  top: 90px;
  left: 209px;
  animation: circle-enter ease-in-out 4s infinite alternate;
}

.outlineCircleSm {
  width: 24px;
  height: 24px;
  background: white;
  border: 2px solid black;
  top: 365px;
  left: 204px;
  animation: circle-enter ease-in-out 4s infinite alternate;
}

.outlineCircleMd {
  width: 16px;
  height: 16px;
  background: white;
  border: 2px solid black;
  top: 400px;
  left: 208px;
  animation: circle-enter ease-in-out 3s infinite alternate;
}

@keyframes circle-enter {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Strokes */

.stroke {
  stroke-dasharray: 2 6;
  animation: dash 10s 0.2s linear infinite;
}

@keyframes dash {
  to {
    stroke-dashoffset: 80;
  }
}

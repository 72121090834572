.divider {
  width: 100%;
  margin: 0;
  padding: 0;
}

.row {
  display: flex;
}

.block {
  height: 5vw;
  width: 5vw;
  background: #f5f3eb;
  animation: block-enter ease-in-out 3s infinite alternate;
  margin: 0;
  padding: 0;
}

.row .block:nth-child(2n) {
  animation: block-enter ease-in-out 3.5s infinite alternate;
}

.row .block:nth-child(3n) {
  animation: block-enter ease-in-out 4s infinite alternate;
}

.row .block:nth-child(4n) {
  animation: block-enter ease-in-out 4.5s infinite alternate;
}

.row .block:nth-child(5n) {
  animation: block-enter ease-in-out 5s infinite alternate;
}

.row + .row .block:nth-child(2n) {
  animation: block-enter ease-in-out 4s infinite alternate;
}

.row + .row .block:nth-child(3n) {
  animation: block-enter ease-in-out 3.5s infinite alternate;
}

.row + .row .block:nth-child(4n) {
  animation: block-enter ease-in-out 3s infinite alternate;
}

.row + .row .block:nth-child(5n) {
  animation: block-enter ease-in-out 2.5s infinite alternate;
}

.row + .row .block {
  animation: block-enter ease-in-out 5s infinite alternate;
}

@keyframes block-enter {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  20% {
    opacity: 0;
    transform: scale(0.5);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
  80% {
    opacity: 1;
    transform: scale(1);
  }
}

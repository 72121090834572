.code {
  color: white;
  padding: 2rem 1rem;
}

.code code {
  font-size: 13px;
  position: relative;
  overflow: auto;
  width: 100%;
  line-height: 1.75;
}

.code pre {
  display: flex;
}

.numbers {
  display: flex;
  flex-direction: column;
  text-align: right;
  padding-right: 1rem;
}

.keyword {
  color: #eb8277;
}

.entity {
  color: #caabf9;
}

.variable {
  color: #f0a965;
}

.constant {
  color: #8abef8;
}

.row2,
.row9 {
  padding-left: 1rem;
}

.row3,
.row8 {
  padding-left: 2rem;
}

.row4,
.row5,
.row6,
.row7 {
  padding-left: 3rem;
}

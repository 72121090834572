.streamlined {
  position: relative;
}

.centerCircle {
  position: absolute;
  height: 18px;
  width: 18px;
  background: black;
  border: 2px solid black;
  top: 31px;
  left: 31px;
  border-radius: 50%;
}

.stroke {
  stroke-dasharray: 2 6;
  animation: dash 10s linear infinite;
}

@keyframes dash {
  to {
    stroke-dashoffset: 80;
  }
}
